import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit {

  constructor(private api: ApiService, private snackBar: MatSnackBar,private spinner: NgxSpinnerService) {
    this.token = undefined;
  }
  token: string | undefined;

  ngOnInit(): void {
  }

  public async send(form: NgForm) {
    this.spinner.show()
    const addContact = await this.api.addContact(form.value).toPromise();
    if (addContact.message) {
      form.reset();

      setTimeout(()=>{                           
        this.spinner.hide()
        this.snackBar.open(
          'Εγγραφήκατε στο newsletter επιτυχώς!',
          'Το κατάλαβα!',
          {
            duration: 5000,
            panelClass: ['snackBar', 'snackBarSuccess'],
          }
        );
      }, 3000);
      
    }
  }

}
