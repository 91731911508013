<div class="container-fluid" style=" position: -webkit-sticky;position: sticky;top: 0;"   (mousedown)="close()">
    <div class="row" >
        <a class="col-md-12 p-0" style="background: #f3f3f3" routerLink="/">
            <img class="img-fluid" src="/assets/VETH_100YEARS_COLOR.png" alt="veth_logo"> 
        </a>
    </div>
    <div class="row" style="border-top:1px solid white;background:#057cfe;color:white" >
        <div class="col-md-12" *ngFor="let item of navItems" class="firstLevel" (click)="clickMenuItem(item)">
            <a >{{item.attributes.name}}</a>
        </div>
       
    </div>
    <div class="row" style="border-top:1px solid white;background:#057cfe;color:white;padding:0.5rem;" >
        <form class="form-inline">
            <div class="input-group col-md-12"><input class="search-query form-control" type="search" placeholder="Αναζήτηση" name="searchterm" [(ngModel)]="query" (keydown.enter)="search()"/>
                <button class="btn btn-primary" type="submit" (click)="search()"><i class="pi pi-search"></i></button></div>
        </form>
    </div>
</div>

<div #template class="modal" (mouseleave)="close()">
 <div class="container-fluid" >
    <div class="row">
        <div class="col-md-4 box" *ngFor="let item of children" >
            <h2 style="color: rgb(25, 110, 172);
            font-weight: bold;
            font-size: 1rem;">{{item.attributes.name}}</h2>
            <a style="font-size: 0.8rem;color: #0d6efd;text-decoration: underline;" (click)="renderContent(item)" translate>more</a>

        </div>
    </div>
 </div>
</div>