import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from '../api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private api: ApiService, private snackBar: MatSnackBar,private spinner: NgxSpinnerService) {
    this.token = undefined;
  }
  token: string | undefined;

  ngOnInit(): void {
  }

  public async send(form: NgForm) {
   
    if (form.valid) {
      //create the object that will be sent to the api
    type objType = {
      to: string, //email receiver
      from: string, //email sender
      subject: string, //the subject of the email
      text: string, //the message
      html: string //the html template of the email
    }

    //this will be the message that will be sent with the email
    const htmlTemplate: string = 
    `
      <head>Μήνυμα για το Βιοτεχνικό Επιμελητήριο Θεσσαλονίκης</head>
      <body>
        <h2>Στοιχεία επικοινωνίας αποστολέα:</h2>
        <p>Ονοματεπώνυμο: ${form.value.name}</p>
        <p>Τηλέφωνο: ${form.value.phone}</p>
        <p>Email: ${form.value.email}</p>
        <div>
          <p>${form.value.message}<p>
        </div>
      </body>
    `

    //defining the object that will be sent to the api
    const myObject: objType = {
      to: "info@veth.gov.gr",
      from: "e-news@veth.gov.gr", 
      subject: "New email",
      text: "Μήνυμα από τη φόρμα επικοινωνίας ΒΕΘ",
      html: htmlTemplate,
    } 
    this.spinner.show()
    await this.api.postEmail(myObject).toPromise();
    form.reset()
    setTimeout(()=>{                           
      this.spinner.hide()
      this.snackBar.open(
        "Το email στάλθηκε επιτυχώς!", 
        "Το κατάλαβα!", 
        { 
          duration: 5000, 
          panelClass: ["snackBar", "snackBarSuccess"]
        }
      )
  
  }, 3000);
    
   

  } else {
    //snackBar opening for failed email sending
    this.snackBar.open(
      "Το email δεν στάλθηκε! Βεβαιωθείτε ότι έχετε αποδεχτεί το captcha.", 
      "Το κατάλαβα!", 
      {
        duration: 5000,
        panelClass: ["snackBar", "snackBarFailure"]
      }
    )
  }

  }

}
