import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { PageComponent } from './page/page.component';
import { CategoryComponent } from './category/category.component';
import { MenuClickSideComponent } from './menu-click-side/menu-click-side.component';
import { MenuClickComponent } from './menu-click/menu-click.component';
import { ContactComponent } from './contact/contact.component';
import { UsefulLinksComponent } from './useful-links/useful-links.component';
import { FaqsComponent } from './faqs/faqs.component';
import { CalendarComponent } from './calendar/calendar.component';
import { PollsComponent } from './polls/polls.component';
import { PollComponent } from './poll/poll.component';
import { EventCalendarComponent } from './event-calendar/event-calendar.component';
import { SearchComponent } from './search/search.component';
import { OpinionFormComponent } from './opinion-form/opinion-form.component';
import { NewsletterComponent } from './newsletter/newsletter.component';

const routes: Routes = [
  {
    path: '',
    component: HomepageComponent,
  },
  {
    path: 'opinion-form',
    component: OpinionFormComponent,
  },
  {
    path: 'newsletter',
    component: NewsletterComponent,
  },
  {
    path: 'page/:id',
    component: PageComponent,
  },
  {
    path: 'event-calendar/:id',
    component: EventCalendarComponent,
  },
  {
    path: 'poll/:id',
    component: PollComponent,
  },
  {
    path: 'menu-click-side/:id',
    component: MenuClickSideComponent,
  },
  {
    path: 'menu-click/:id',
    component: MenuClickComponent,
  },
  {
    path: 'category/:id/:page',
    component: CategoryComponent,
  },
  {
    path: 'useful-links',
    component: UsefulLinksComponent,
  },
  {
    path: 'calendar',
    component: CalendarComponent,
  },
  {
    path: 'search',
    component: SearchComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'faqs',
    component: FaqsComponent,
  },
  {
    path: 'polls',
    component: PollsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: true,scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
