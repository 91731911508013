<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box"
    [fullScreen]="true"></ngx-spinner>
<div id="contact" class="container bootstrap snippets bootdeys" style="margin-top:2rem">
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
            <h4 translate style="margin-left: 1rem;">newsletterForm</h4>
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-3"></div>
        <div class="col-md-6">
            <form id="ajax-form form-main" role='form' name='ajax-form' #form="ngForm" style="padding: 1rem;">
                <div class="form-group">
                    <label for="name" translate>name<span style="color:red; margin-left:3px">*</span></label>
                    <input id="name" class="form-control" name="crmRecipientName" type="text" ngModel required>
                </div> 

                <div class="form-group">
                    <label for="lastName" translate>lastName<span style="color:red; margin-left:3px">*</span></label>
                    <input id="lastName" class="form-control" name="crmRecipientSurname" type="text" ngModel required>
                </div> 
                <div class="form-group">
                    <label for="phone" translate>phone<span style="color:red; margin-left:3px">*</span></label>
                    <input id="phone" class="form-control" name="crmRecipientPhone" type="text" ngModel required>
                </div> 
                <div class="form-group">
                    <label for="email" translate>Email<span style="color:red; margin-left:3px">*</span></label>
                    <input id="email" class="form-control" name="crmRecipientEmail" type="text" ngModel required>
                </div> 
             
                <div class="form-group" style="margin-top: 1rem;">
                    <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel"
                    [(ngModel)]="token" required
                    [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
                </div>
                <div class="form-group" style="margin-top: 1rem;">
                    <button id="send" class="btn btn-primary btn-shadow btn-rounded w-md" (click)="send(form)" translate>register</button>
                 </div>
            </form>
        </div>
        <div class="col-md-3"></div>
    </div>
</div>